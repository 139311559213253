/* eslint-disable max-len */
import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';

const TermsOfService = () => (
  <>
    <Header />
    <div className="darkOnLight">
      <div className="content">
        <p>
          <h1>Terms & User Agreement</h1>
          <p>
            This User Agreement (“Agreement”) constitutes a binding written contract between Autotrace. (“Autotrace,”
            “we,” “us,” or “our”) and you, on behalf of yourself and any organization or company that you represent
            (together, “you”). This Agreement governs your access to and use of this website and the related online
            services. As used in this Agreement, “Service” includes all Service content, code, related documentation,
            and underlying technology, as well as all updates, upgrades, improvements, bug fixes, and other
            modifications to the same. This Agreement applies to all users of the Service, including subscribing
            organizations and their respective users.
          </p>
          <p>
            You accept this Agreement and our Privacy Policy by accessing or using the Service (including when you log
            in to or pay fees for the Service) or when you click to agree to this Agreement (where applicable). Do not
            access or use the Service if you do not understand or agree to this Agreement and our Privacy Policy.
          </p>
          <p>
            We reserve the right to modify this Agreement at any time. All changes will be effective immediately upon
            posting of the updated Agreement to the Service and, by accessing or using the Service after changes are
            posted, you agree to those changes unless, in the case of material changes only, you choose to exercise your
            right below to terminate your Service account if the material changes are not acceptable to you. Material
            changes will be clearly posted on the Service or otherwise communicated to you.
          </p>
          <p>
            CLASS ACTION WAIVER: By accessing or using the Service, you agree to give up, and you hereby waive, any
            rights to participate in a class action lawsuit or representative action with respect to any disputes or
            claims arising out of or relating to this Agreement or the Service.
          </p>
          <p>
            ARBITRATION NOTICE: This Agreement contains a binding arbitration provision. You agree that, except for
            certain types of disputes as described in the arbitration provision below, all disputes between you and
            Autotrace will be resolved by mandatory binding arbitration and you waive any right to participate in a
            class-wide arbitration.
          </p>
          <p>
            Privacy Policy: We may collect certain information from and about you as described in our Privacy Policy,
            which is incorporated into this Agreement and describes our information collection, use, and sharing
            practices.
          </p>
          <p>
            Your Account: You will be required to create an account in order to use the Service. You will be required to
            submit certain information in order to create your account and will be required to establish a password. You
            are responsible for maintaining the confidentiality of any information you use in connection with the
            Service, including any username, your password, and any invitation code provided by Autotrace. You are
            responsible for all activity on your account. If you believe someone else has accessed your account, please
            change your password and notify us immediately at [Contact Email]. You will not transfer your Service
            account to or share your Service account with any other person. Your Service account is personal to you.
          </p>
          <p>
            Limited License: Provided you comply with this Agreement, Autotrace hereby grants you a limited,
            non-exclusive, revocable, nonassignable, nontransferable, nonsublicensable license to access and use the
            Service, for the duration of your subscription to the Service, solely for your internal business purposes to
            conduct your marketing campaigns and, in any event, in accordance with any related documentation provided by
            Autotrace.
          </p>
          <h2>USE RESTRICTIONS:</h2>
          You will not, nor will you permit, direct, or assist any person or entity to:
          <ul>
            <li>Access or use the Service for purposes other than as expressly permitted by this Agreement;</li>
            <li>
              Copy, reproduce, duplicate, replicate, display, publish, post, distribute, license, sell, rent, lease,
              encumber, or transfer the Service or any part thereof;
            </li>
            <li>
              Adapt, update, translate, modify, or prepare any derivative works of the Service or any part thereof;
            </li>
            <li>
              Disassemble, decompile, reconfigure, reverse-engineer, discover the underlying ideas behind, or discover
              the source code of the Service or any part thereof (except to the extent applicable laws may specifically
              prohibit this restriction);
            </li>
            <li>Combine the Service or any part thereof with other computer code or materials of any kind;</li>
            <li>
              Obscure, remove, or alter any proprietary markings, designations, legends, symbols, labels, or notices in
              or on the Service;
            </li>
            <li>
              Develop, market, or provide on your own behalf or any other person’s or entity’s behalf any software,
              products, or services that compete with or are substantially similar to the Service or any of its
              features;
            </li>
            <li>Use the Service to develop, create, produce, enhance, or add to any database;</li>
            <li>
              Access, use, or analyze the Service for any purpose that is to Autotrace’s detriment or commercial
              disadvantage;
            </li>
            <li>
              Use the Service as part of any effort to compete with Autotrace or to provide services as a service
              bureau;
            </li>
            <li>Act maliciously against the business interests or reputation of Autotrace or any Service user;</li>
            <li>
              Engage in data mining or similar data gathering or extraction activities or retrieve data or other content
              from the Service for purposes of creating or compiling that content for any purpose other than your
              authorized use of the Service as permitted by the above license;
            </li>
            <li>
              Access, use, or copy any portion of the Service, including any of its content, through the use of indexing
              agents, spiders, scrapers, bots, web crawlers, or other automated devices or mechanisms;
            </li>
            <li>
              Use the Service for unlawful purposes;
              <ul>
                <li>
                  Use the Service in a manner that infringes, violates, or misappropriates our or any third party’s
                  rights, including patent, copyright, trademark, trade secret, other intellectual property rights, and
                  privacy rights;
                </li>
                <li>Send or post any unsolicited messages, chain letters, spam, or junk mail using the Service;</li>
                <li>Impersonate or attempt to impersonate another person or business while using the Service;</li>
                <li>Commit fraud or falsify information in connection with your use of the Service;</li>
              </ul>
              <li>
                Submit or transmit through or using the Service any information, content, or messages that are
                defamatory, libelous, indecent, pornographic, obscene, otherwise objectionable or harmful, or that
                violates the legal rights of third parties;
              </li>
              <li>
                Use the Service in combination with any other products, software, or services without our specific prior
                written consent;
              </li>
              <li>
                Post, transmit, input, upload, or otherwise provide any information or materials that contain viruses,
                worms, Trojan horses, logic bombs, time bombs, cancelbots, malware, ransomware, adware, or other harmful
                computer code or programming routines, including those designed to or that reasonably may disable,
                damage, impair, interfere with, surreptitiously intercept, or expropriate the Service or any computers,
                hardware, software, system, data, or networks; nor Engage in activities that aim to render the Service
                or associated services inoperable or to make their use more difficult.
              </li>
            </li>
          </ul>
          <p>
            Intellectual Property: The Service (including its text, audio, video, graphics, charts, photographs,
            interfaces, icons, software, computer code, APIs, user interface, user experience, data, trademarks, logos,
            slogans, names of products and services, documentation, other components and content, and the design,
            selection, and arrangement of content), all right, title, and interest in and to the Service, and all ideas,
            concepts, know-how, methodologies, techniques, discoveries, and inventions related to the same (including
            all patent, copyright, trademark, trade secret, and other intellectual property and proprietary rights
            throughout the world in or to any of the foregoing), as well as all modifications, enhancements,
            improvements, and derivative works of any of the foregoing, regardless of when created or by whom, are
            exclusively the property and will remain exclusively the property of Autotrace or, as applicable, its
            suppliers and licensors. The Service is protected by copyright, trademark, and other intellectual property
            laws. Any unauthorized use of any trademarks, trade dress, copyrighted materials, or any other intellectual
            property belonging to Autotrace or any third party is strictly prohibited and may be prosecuted to the
            fullest extent of the law. You agree to respect all copyright and other legal notices, information, and
            restrictions on or in any content on or accessed through the Service. The Service may contain references to
            third-party marks and copies of third-party copyrighted materials, which are the property of their
            respective owners. Except as may be expressly stated in this Agreement, no licenses or other rights, express
            or implied, are granted by Autotrace to you under any patent, copyright, trademark, trade secret, or other
            intellectual property right of Autotrace and all such rights are reserved and retained by Autotrace.
            Additionally, you specifically acknowledge and understand that this Agreement does not confer upon you or
            any other person or entity any interest in or right to use any trademark or service mark of Autotrace or
            pertaining to the Service, including “Autotrace” and “autotrace.ai.” You will not, without our specific
            prior written consent, use our names, logos, trademarks, or service marks. Prohibited uses and disclosures
            include advertising and marketing materials, publications, sales presentations, press releases, and public
            announcements. Copyright © 2020 Autotrace. All rights reserved.
          </p>
          <p>
            Pricing and Payment: You will pay to Autotrace all amounts payable under this Agreement, including all fees
            specified during the online order process, and all such amounts must be remitted through our website.
            Subscription fees are due monthly or annually in advance, as applicable, depending on the subscription plan
            you have selected. Information about our collection and use of payment-related information is described in
            our Privacy Policy. If the credit or debit card information that you submit is incorrect or invalid, your
            payment will not be processed. We have no responsibility or liability if your credit or debit card is
            declined by your financial institution. Payments are processed by our third-party payment processor. All
            payment obligations under this Agreement are nonrefundable. Autotrace reserves the right to modify its
            pricing and subscription plans at any time upon posting of such modifications on the Service or other notice
            to you, provided those modifications will not become effective until your next renewal subscription term.
          </p>
          <p>
            Term and Termination: This Agreement commences on the earlier of (a) your creation of a Service account or
            (b) your first access to or use of the Service and continues, unless terminated in accordance with this
            Agreement, for an initial subscription term of 90 days. This Agreement will then automatically renew for
            subsequent 30-day renewal terms unless and until terminated by either party in accordance with this
            Agreement. You may terminate this Agreement at any time and for any reason, including if we make material
            changes to this Agreement that are not acceptable to you, upon 30 days’ prior written notice to Autotrace.
          </p>
          <p>
            Compliance with Law. All marketing campaigns are yours alone, as Autotrace is merely a passive technology
            provider. You acknowledge and agree that your use of the Service (including the transmission of text
            messages) is subject to certain laws and regulations and that you are required to use the Service in full
            compliance with those laws and regulations, including without limitation the Federal Trade Commission’s
            Telemarketing Sales Rule, the Federal Communications Commission’s Telephone Consumer Protection Act and all
            regulations promulgated thereunder (together, the “TCPA”), and all state, federal, and foreign (a) real
            estate professional licensee rules of conduct, (b) Do-Not-Call (“DNC”) list prohibitions, (c) applicable
            licensing and bonding requirements, (d) consumer cancellation rights, (e) wireless calling restrictions, (f)
            restrictions on the use of automatic telephone dialing systems and pre-recorded messages, (g) opt-out rules,
            (h) mandatory disclosures, (i) intellectual property rights and restrictions, and (j) applicable industry
            regulations. All Autotrace offers are void where prohibited by law. Skip-Traced Numbers. If you elect to use
            our skip-traced telephone number data, which is by its nature not guaranteed to be comprehensive or
            accurate, you assume all risks related to the same, including without limitation all risks related to wrong
            and reassigned telephone numbers and claims challenging any purported consents from target consumers. You
            understand that no consent can be guaranteed to be associated with any skip-traced telephone number. Cell
            Phones. You understand that the TCPA prohibits certain types of telemarketing calls to cell phones and you
            agree not to use the Service to market or send text messages to cell phones except with proper consent or as
            may be otherwise allowed by law. You assume all risks if you elect to contact cell phones using the Service.
            You will not engage in any SMS or other text communications except as allowed by law. SAN Numbers & DNC. You
            acknowledge that certain sellers and telemarketers may be required to purchase their own Subscription
            Account Number (“SAN”) to the National DNC Registry, including all area codes to which you call or transmit
            text messages. You agree to obtain a SAN number and all applicable area codes and to purchase and scrub
            against any applicable state or federal DNC list, unless exempt from such requirements. You will not from
            marketing to any person who has opted out of future marketing or text messages from you. You, alone, and not
            Autotrace, will be responsible for maintaining and enforcing an internal DNC list of consumers who have
            opted out of your marketing or text messages. Your Systems. It is your responsibility to obtain the
            necessary computer equipment and software to operate and use the Service and its functionality. Data
            Charges. You are responsible for any telecommunications and data charges and rates incurred by using the
            Service.
          </p>
          <p>
            NO LEGAL, FINANCIAL, OR TAX ADVICE: WE DO NOT PROVIDE LEGAL, FINANCIAL, OR TAX-RELATED ADVICE OR SERVICES.
            NO LEGAL, FINANCIAL, OR TAX-RELATED ADVICE OR COUNSEL IS GIVEN, NOR WILL BE DEEMED TO HAVE BEEN GIVEN, BY
            ANY AUTOTRACE PARTY (AS DEFINED BELOW) OR BY THE SERVICE. AUTOTRACE DOES NOT ASSUME ANY RESPONSIBILITY FOR
            ENSURING THAT YOUR MARKETING OR TEXT MESSAGE CAMPAIGNS MEET APPLICABLE LEGAL REQUIREMENTS. NEITHER THE
            SERVICE NOR AUTOTRACE PERFORMS ANY COST-BENEFIT ANALYSES, DRAWS LEGAL CONCLUSIONS, NOR PROVIDES LEGAL
            ADVICE, OPINIONS, OR RECOMMENDATIONS.
          </p>
          <p>
            Your Warranties: You hereby represent and warrant that:
            <ul>
              <li>The Service will not be accessed or used in any manner not permitted by this Agreement;</li>
              <li>
                You are not, nor do you work for or provide services to, a competitor of Autotrace or any person or
                entity that develops, markets, or provides any software, products, or services that are competitive with
                or substantially similar to the Service or any of its features, nor do you or will you seek to engage in
                those activities;
              </li>
              <li>
                You will notify Autotrace within forty-eight (48) hours of your initial usage and operation of the
                Service if you have ever developed, marketed, or provided on your own behalf or any other person’s or
                entity’s behalf any software, products or services that compete with or are substantially similar to the
                Service or any of its features, or intend to do so in the future, and agree that any failure to so
                notify Autotrace will be treated as conclusive proof in favor of Autotrace in any potential dispute as
                to the source of any idea, concept or function in any copyright, trademark, patent, or other
                intellectual property dispute;
              </li>
              You will perform your obligations and exercise your rights under this Agreement in compliance with all
              applicable laws and regulations, including those discussed above in this Agreement;
              <li>
                All data and other information you provide in connection with your access to and use of the Service is
                true, accurate, and complete to the best of your knowledge and belief;
              </li>
              <li>You have all legal right to provide such data and information to us or through the Service; and</li>
              <li>
                You have the authority to enter into this Agreement and you are under no contractual obligation that
                will interfere with your ability to perform under this Agreement.
              </li>
            </ul>
          </p>
          <p>
            Indemnification: You will indemnify, hold harmless, and defend Autotrace, its affiliates, and its and their
            respective directors, officers, owners, employees, agents, representatives, contractors, consultants,
            suppliers, licensors, successors, and assigns (together, “Autotrace Parties”) from and against any and all
            liabilities, losses, damages, claims, suits, demands, complaints, actions, settlements, judgments,
            investigations, fines, penalties, costs, and expenses (including reasonable attorneys’ fees and court costs)
            that arise, directly or indirectly, out of or in connection with, or are related in any way to
            <ul>
              <li>(a) this Agreement or your breach of this Agreement, </li>
              <li>
                (b) your access to or use of the Service (including your transmission and receipt of text messages and
                your payment of fees),{' '}
              </li>
              <li>
                (c) your misuse of or reliance on any material, data, or other information obtained from the Service,{' '}
              </li>
              <li>
                (d) your submission of information, documents, and other content (including personal information) using
                the Service,
              </li>
              <li>(e) your violation of or noncompliance with applicable laws or regulations, or </li>
              <li>(f) your negligence, willful misconduct, or fraud. </li>
            </ul>
            We reserve, and you grant to us, the exclusive right to assume the defense and control of any matter subject
            to indemnification by you.
          </p>
          <p>
            DISCLAIMER OF WARRANTIES: THE SERVICE IS PROVIDED “AS IS,” “AS AVAILABLE,” AND WITHOUT ANY WARRANTY OF ANY
            KIND (EXPRESS, IMPLIED, OR OTHERWISE), INCLUDING THE WARRANTIES OF MERCHANTABILITY AND FITNESS FOR ANY
            PARTICULAR PURPOSE. AUTOTRACE DOES NOT WARRANT THAT ANY DATA, INFORMATION, OR MATERIAL ON THE SERVICE IS
            ACCURATE OR RELIABLE. AUTOTRACE DOES NOT WARRANT OR GUARANTEE THE QUALITY, COMPLETENESS, TIMELINESS, OR
            AVAILABILITY OF THE SERVICE. AUTOTRACE DOES NOT WARRANT OR GUARANTEE THAT THE SERVICE WILL BE UNINTERRUPTED
            OR ERROR FREE, THAT ANY DEFECTS IN THE SERVICE WILL BE CORRECTED, OR THAT THE SERVICE OR THE SERVERS THAT
            MAKE THE SERVICE AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL CONDITIONS OR COMPONENTS. AUTOTRACE IS NOT
            RESPONSIBLE FOR ANY TYPOGRAPHICAL ERRORS ON THE SERVICE. AUTOTRACE HAS NO RESPONSIBILITY FOR THE TIMELINESS,
            DELETION, MISDELIVERY, OR FAILURE TO STORE ANY USER COMMUNICATION. TO THE MAXIMUM EXTENT PERMITTED BY
            APPLICABLE LAW, AUTOTRACE EXPRESSLY DISCLAIMS ALL WARRANTIES OF ANY KIND WITH RESPECT TO THE SERVICE,
            INCLUDING WITHOUT LIMITATION THOSE REGARDING AVAILABILITY, QUALITY, ACCURACY, MERCHANTABILITY, FITNESS FOR
            ANY USE OR PURPOSE, COMPATIBILITY WITH ANY STANDARDS OR USER REQUIREMENTS, TITLE, AND NONINFRINGEMENT, AS
            WELL AS ANY ARISING BY OPERATION OF LAW OR FROM A COURSE OF DEALING OR USAGE IN TRADE. YOU ACKNOWLEDGE AND
            AGREE THAT THE SERVICE MAY BE SUBJECT TO LIMITATIONS, DELAYS, AND OTHER PROBLEMS INHERENT IN THE USE OF
            TELECOMMUNICATIONS, THE INTERNET, WIRELESS NETWORKS, AND ELECTRONIC COMMUNICATIONS. AUTOTRACE IS NOT
            RESPONSIBLE FOR ANY DELAYS, INACCURACIES, DELIVERY FAILURES, OR OTHER FAILURES OR DAMAGE RESULTING FROM
            THOSE PROBLEMS OR ANY OTHER PROBLEMS OUTSIDE OF AUTOTRACE’S REASONABLE AND DIRECT CONTROL, INCLUDING WITHOUT
            LIMITATION TELECOMMUNICATIONS SERVICES, THE INTERNET, YOUR WIRELESS NETWORKS, AND ANY THIRD-PARTY COMPONENTS
            OR SOFTWARE. YOU ARE NOT PURCHASING A “FRANCHISE,” “BUSINESS OPPORTUNITY,” “STRATEGIC MARKETING PLAN,”
            “TARGETED MARKETING PLAN,” OR “EXCLUSIVE TERRITORY” FROM AUTOTRACE. YOU UNDERSTAND THAT THE SERVICE MAY
            INVOLVE COMPLEX PROCESSES AND TECHNOLOGY AND THAT IT MAY TAKE TIME, EFFORT, AND SKILL TO UNDERSTAND AND
            OPERATE THE SERVICE. WE RECOMMEND THAT YOU ALLOW AMPLE TIME TO ACCESS, SET UP, TEST, AND UNDERSTAND THE
            SERVICE BEFORE YOU USE THE SERVICE FOR YOUR MARKETING CAMPAIGNS. AUTOTRACE DOES NOT MAKE ANY CLAIMS OR
            GUARANTEES THAT YOU WILL BRING IN ANY REVENUE NOR OPERATE ANY PROFITABLE SERVICES OR BUSINESSES BY USING THE
            SERVICE. WE MAKE NO REPRESENTATION THAT THE SERVICE IS APPROPRIATE OR AVAILABLE FOR USE IN LOCATIONS OTHER
            THAN THE UNITED STATES. IF YOU CHOOSE TO ACCESS OR USE THE SERVICE FROM LOCATIONS OTHER THAN THE UNITED
            STATES, YOU DO SO AT YOUR OWN RISK AND YOU ARE RESPONSIBLE FOR COMPLYING WITH APPLICABLE LAWS AND
            REGULATIONS. YOUR USE OF THE SERVICE IS AT YOUR OWN RISK AND YOU, ALONE, ARE RESPONSIBLE FOR ANY DAMAGE TO
            YOUR COMPUTER HARDWARE, SOFTWARE, SYSTEMS, AND NETWORKS, ANY LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OF
            ANY INFORMATION FROM THE SERVICE, AND ANY OTHER DAMAGE OR LOSS THAT MAY BE INCURRED. NO ADVICE OR
            INFORMATION, ORAL OR WRITTEN, OBTAINED BY YOU FROM AUTOTRACE OR IN ANY MANNER FROM THE SERVICE CREATES ANY
            WARRANTY.
          </p>
          <p>
            LIMITATION OF LIABILITY: TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL THE AUTOTRACE
            PARTIES BE LIABLE FOR ANY CONSEQUENTIAL, INCIDENTAL, SPECIAL, INDIRECT, PUNITIVE, OR EXEMPLARY DAMAGES OR
            LOSSES OF ANY KIND (INCLUDING WITHOUT LIMITATION LOSS OF PROFITS, GOODWILL, USE, DATA, OR OTHER INTANGIBLE
            ITEMS), ARISING OUT OF OR IN CONNECTION WITH THE SERVICE OR YOUR ACCESS TO OR USE OF, OR INABILITY TO ACCESS
            OR USE, THE SERVICE (INCLUDING WITHOUT LIMITATION THE INPUT OF PERSONAL INFORMATION USING THE SERVICE, ANY
            RELIANCE ON INFORMATION DISPLAYED ON THE SERVICE, AND ANY USE OF THIRD-PARTY COMPONENTS), WHETHER THE CLAIM
            IS BASED IN CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY, WARRANTY, OR OTHERWISE, AND EVEN IF A
            AUTOTRACE PARTY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES OR LOSSES. WITHOUT LIMITING THE
            FOREGOING, THE AUTOTRACE PARTIES’ MAXIMUM AGGREGATE LIABILITY UNDER OR IN CONNECTION WITH THIS AGREEMENT OR
            THE SERVICE, ARISING OUT OF ANY CLAIM WHATSOEVER AND REGARDLESS OF THE FORM OF ACTION, WILL IN NO EVENT
            EXCEED THE AMOUNT EQUAL TO THE SUBSCRIPTION FEES ACTUALLY PAID BY YOU TO AUTOTRACE IN THE ONE-MONTH PERIOD
            IMMEDIATELY PRECEDING THE DATE THE CLAIM AROSE. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION
            OF CERTAIN DAMAGES, SO SOME OF THE ABOVE EXCLUSIONS AND LIMITATIONS MAY NOT APPLY TO YOU.
          </p>
          <p>
            Noncircumvention: During the term of this Agreement and for a period of one year after termination, you will
            not, in any manner, directly or indirectly, circumvent or attempt to circumvent this Agreement, including
            without limitation by forming, joining, or in any way participating in any corporation, partnership, limited
            partnership, limited liability company, syndicate, or other firm, entity, or group (or otherwise act in
            concert with any person, firm, entity, or group) for the purpose of taking any action that is in
            circumvention of this Agreement or is restricted or prohibited under this Agreement.
          </p>
          Nonsolicitation: While this Agreement is in effect and for a period of one year after termination, you will
          not solicit for employment, hire, or seek or engage the services of any then-current or former employee or
          contractor of Autotrace.
        </p>
        <p>
          Service Availability and Modifications: Any features, materials, products, or services made available through
          the Service are subject to availability. Autotrace may change the Service and the features, materials,
          products, and services made available through the Service at any time without notice or liability. Third-Party
          Websites and Services: The Service may link to, or be linked to, websites and services not maintained or
          controlled by Autotrace. Those links are provided as a convenience and Autotrace is not responsible for
          examining or evaluating the content or accuracy of, and does not warrant or endorse, any third-party website
          or services or any products or services made available through those websites or services. Please take care
          when leaving the Service to visit a third-party website or service. You should read the terms of use and
          privacy policy for each website and service that you use.
        </p>
        {/* <p>
          Choice of Law and Venue: This Agreement is governed by and will be construed according to the laws of the
          State of Delaware without giving effect to its conflict-of-law principles and, as applicable, the federal laws
          of the United States. The United Nations Convention for the International Sale of Goods does not apply. The
          state and federal courts (as applicable) located in Delaware or Colorado shall, at Autotrace’s option, have
          exclusive jurisdiction and venue over any non-arbitral dispute initiated by Autotrace under Section 20. The
          parties consent to such exclusive jurisdiction.
        </p> */}
        <p>
          Arbitration Agreement; Class Waiver; Waiver of Trial by Jury: Please read the following paragraphs carefully
          because they require you to arbitrate disputes with Autotrace and limit the manner in which you can seek
          relief from us.
          <ul>
            <li>
              Applicability.Any dispute, claim, or controversy arising out of or relating to the Service or this
              Agreement, including without limitation the breach, termination, enforcement, interpretation, or validity
              of this Agreement (together, “Disputes”), will be resolved by binding arbitration on an individual basis
              as described in this section (this “Arbitration Agreement”).
            </li>
            <li>
              However, notwithstanding the foregoing, in the event of any actual, alleged, or threatened violation of
              confidentiality or violation of Autotrace’s intellectual property or other proprietary rights, Autotrace
              may immediately resort to court proceedings in a court of competent jurisdiction, including to seek
              temporary, interim, or preliminary injunctive relief and to do so without posting bond, proving damages,
              or meeting any similar requirement. Autotrace will be entitled to recover its reasonable attorneys’ fees
              and court costs from you in the event it is the prevailing party in any such court proceedings against
              you. No institution of any action for temporary, interim, or preliminary injunctive relief will constitute
              a waiver of the right or obligation of either party to submit any claim seeking relief other than
              temporary, interim, or preliminary injunctive relief to arbitration.
            </li>
            <li>
              This Arbitration Agreement applies to you, all Autotrace Parties, their respective predecessors in
              interest, successors in interest, and assigns, and all authorized and unauthorized users of the Service.
            </li>
            {/* <li>
              Arbitrator. Arbitration proceedings will be administered by the American Arbitration Association (“AAA”)
              before a three-arbitrator panel. Each party will appoint one arbitrator and those two arbitrators will,
              together, agree upon a third arbitrator. The decision of the panel will be final and binding. Any final
              award or judgment may be filed, confirmed, and enforced in any court of competent jurisdiction. Autotrace
              will be entitled to recover its reasonable attorneys’ fees and all costs of the arbitration from you in
              the event it is the prevailing party in any such arbitration proceedings against you. Otherwise, the
              parties shall each bear their own attorneys’ fees and equally split all costs of the arbitration.
            </li> */}
            <li>
              Any arbitration proceeding cannot be consolidated or joined with any other proceeding and will not proceed
              as a class action. The parties understand that they would have had a right or opportunity to litigate
              Disputes through a court, to have a judge or jury decide their case, and to participate in a class action
              or other proceeding involving multiple claimants, but they have instead chosen to have all Disputes
              decided through individual arbitration.
            </li>
            {/* <li>
              Place; Federal Arbitration Act. The place of arbitration will be Denver, Colorado, unless otherwise agreed
              to in writing by all parties to the arbitration. This Arbitration Agreement evidences a transaction
              involving interstate commerce and the Federal Arbitration Act, 9 U.S.C. Sections 1-16, will govern the
              interpretation, enforcement, and proceedings pursuant to this Arbitration Agreement.
            </li> */}
            <li>
              {' '}
              Confidentiality. Any and all actions taken under this Arbitration Agreement, including all filings,
              orders, judgments, and awards made in any arbitration proceeding, are confidential and must not be
              disclosed to any third party.
            </li>
          </ul>
        </p>
        <p>
          Force Majeure: Autotrace will not be liable to you or to any third party for any delay or other failure to
          perform under this Agreement that is due to causes beyond Autotrace’s control, including without limitation
          acts of God, terrorism, civil disorders, labor controversy, riot, acts of a public enemy, acts of the United
          States of America or any state, territory or political division thereof, other acts of any government, fires,
          floods, earthquakes, blizzards, other extraordinary elements of nature, telecommunications failures, Internet
          failures, and similar causes and events.
        </p>
        <p>
          Feedback: Autotrace welcomes comments regarding the Service. If you submit comments or feedback to us
          regarding the Service, they will not be considered or treated as confidential. We may use any comments and
          feedback that you send us at our discretion and without attribution or compensation to you.
        </p>
        <p>
          Confidentiality: You will not use any Autotrace Confidential Information for any purpose except as reasonably
          necessary to access or use the Service as expressly permitted by this Agreement, where “Autotrace Confidential
          Information” means all information disclosed in connection with the Service or this Agreement by or on behalf
          of a Autotrace Party to you or otherwise obtained by you, whether or not identified as “confidential,” that
          Autotrace considers or protects as confidential or that should reasonably be understood to be confidential
          given the content of the information and the circumstances of disclosure, including without limitation all
          practices, strategies, tactics, business plans, technical specifications, and trade secrets related to the
          Service or any other product or service developed, marketed, or provided by an Autotrace Party. You will not
          disclose any Autotrace Confidential Information to any third party. You will use the same measures to protect
          Autotrace Confidential Information from unauthorized use and disclosure as you use to protect your own most
          confidential information, but in no event less than a reasonable degree of care. Autotrace Confidential
          Information may be in any form and includes all copies of Autotrace Confidential Information. Autotrace
          Confidential Information is solely the property of Autotrace. This Agreement does not create any agency,
          partnership, joint venture relationship, other form of joint enterprise, employment, or fiduciary relationship
          between the parties, their affiliates, or their respective employees, contractors, or agents. Neither party
          has any authority to contract for or bind the other party in any manner or make any representation or
          commitment on behalf of the other party. Audit. Autotrace and its designated representatives may, at their
          expense, audit, examine, and make copies of data, documents, information, and other records in your possession
          or control that relate to or concern the Service in order to determine your compliance with this Agreement.
        </p>
        <p>
          Notices: Except as may be otherwise specified in this Agreement, all notices, consents, and other
          communications permitted or required to be given under this Agreement must be in writing and addressed to the
          recipient and will be deemed given: upon delivery if personally delivered with fees prepaid, including by a
          recognized courier service; upon receipt if delivered by certified or registered mail, postage prepaid and
          return receipt requested, as indicated by the date on the signed receipt; or, where you are the recipient,
          upon delivery by email to the email address for you or your organization on file with Autotrace. Where
          Autotrace is the recipient, a copy of each communication must be sent to [Contact Email Address] in order to
          be valid. Please print or otherwise save a copy of this Agreement and all notices, consents, and other
          communications for your reference.
        </p>
        <p>
          Assignment: We may assign our rights and delegate our duties under this Agreement at any time to any party
          without notice to you. You will not assign this Agreement or your rights or obligations under this Agreement
          without our prior written consent. Any attempted assignment by you will be void. Subject to the foregoing,
          this Agreement is binding upon and inures to the benefit of the parties’ respective successors and assigns.
        </p>
        <p>
          Entire Agreement: This Agreement, including our Privacy Policy and any addendums, schedules, or additional
          terms incorporated by reference, constitutes the entire understanding and agreement between you and Autotrace
          with regard to your access to and use of the Service and supersedes all prior and contemporaneous
          communications, understandings, and agreements, whether written or oral, regarding the same. Autotrace’s
          failure to enforce any provision of this Agreement or take an action under this Agreement will not constitute
          a waiver of the applicable provision or any other provision. Any waiver of any term of this Agreement by
          Autotrace will be effective only if in writing and signed by Autotrace.
        </p>
        <p>
          Interpretation: If any term of this Agreement is held to be invalid or unenforceable in any jurisdiction, that
          term will be severed from the remaining terms to the extent of the invalidity or unenforceability, without
          invalidating any other term of this Agreement, which will remain in full force and effect. The headings in
          this Agreement are for convenience only and will not affect the construction or interpretation of this
          Agreement.
        </p>
        <p>
          No Third-Party Beneficiaries: This Agreement does not confer any rights, remedies, or benefits upon any person
          or entity other than you and Autotrace.
        </p>
        <p>
          Survival: Any provisions of this Agreement that by their nature or content are intended to or would be
          reasonably understood to apply after termination of this Agreement or of your access to or use of the Service,
          including all provisions regarding intellectual property, use restrictions, confidentiality, indemnification,
          limitation of our liability, or dispute resolution, will survive any termination of this Agreement.
        </p>
        <p>
          Contact Us: If you have any questions or comments regarding this Agreement or the Service, please contact us
          by email at{' '}
          <a className="quietButton" href="mailto:contact@autotrace.ai">
            contact@autotrace.ai
          </a>
        </p>
      </div>
    </div>
    <Footer />
  </>
);

export default TermsOfService;
