/* eslint-disable max-len */
import React from 'react';
import '../App.css';
import { Link } from '@reach/router';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Graphic from '../images/autotracegraphic.png';

const Home = () => (
  <>
    <Header />
    {/* <div className='content'> */}
    <div className="lightOnDark">
      <div className="content">
        <div className="sideFlow">
          <h1>Find the persons behind the property </h1>
          <p>Uncover contact leads for any LLC or LP-owned property in the US. </p>
          <Link to="/account" className="loudButton">
            Find property decision-makers
          </Link>
        </div>
        <div className="graphic">
          <img src={Graphic} alt="skyline of buildings" height="200px" />
        </div>
      </div>
    </div>

    <div className="darkOnLight">
      <div className="content">
        <h1>How to use Autotrace</h1>
        <div className="thirds">
          <h2>1</h2>
          <h3>Upload your list</h3>
          <p>Upload a list of LLC or LP-owned properties.</p>
        </div>
        <div className="thirds">
          <h2>2</h2>
          <h3>Get matches</h3>
          <p>Our system scans records to find up-to-date contact information.</p>
        </div>
        <div className="thirds">
          <h2>3</h2>
          <h3>Use your matches</h3>
          <p>Receive the names and contact information of the properties&apos; decision-makers.</p>
        </div>
      </div>
    </div>
    <div className="brightChunk">
      <div className="content">
        <h1>Why Autotrace</h1>
        <p>Autotrace is quick, competitively priced, and simple. Just upload a csv of LLC/LP-owned properties.</p>
        <p>
          Instead of having your list run against a credit database&mdash; returning unhelpful holding companies&mdash;
          our automated system goes directly to the source: these companies&apos; registration records, which lead to
          real persons.{' '}
        </p>
        <p>Now you can find leads on LLC or LP-owned properties.</p>
      </div>
      {/* <div className="content">
                        <h1>Don't hire a virtual assistant</h1>
                        <p>The servies you've used pull their matches out of old databases. You're competitive. You want to know </p>
                        <h2>Faster</h2>
                        <p>Autotracer is automated for a reason. Get your matches in hours not days.</p>
                        <h2>More Reliable</h2>
                        <p>Get your matches from the source- the registration records of holding companies.</p>
                        <h2>Less Expensive</h2>
                        <p>Pay just ??? per match.</p>
                    </div> */}
    </div>

    <div className="darkOnLight">
      <div className="content">
        <h1>Upload your list, get matches, and reach the decision-makers</h1>
        <p>
          <Link to="/account" className="mediumButton">
            UPLOAD LIST
          </Link>
        </p>
      </div>
    </div>
    <Footer />
  </>
);

export default Home;
