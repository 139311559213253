import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';

const Contact = () => {
  return (
    <>
      <Header />
      <div className="darkOnLight">
        <div className="content">
          <h1>Contact Us</h1>
          <p>
            The best way to reach us is{' '}
            <a className="quietButton" href="mailto:contact@autotrace.ai">
              contact@autotrace.ai
            </a>
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Contact;
