import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #3B3B3B;
  };

  h1, h2 {
    font-size: 2em;
    font-weight: normal;
  }

  h1 {
    margin-top: 1px;
  }
  h2 {
    color: #1B6DC1;
    margin-top: 1px;
    margin-bottom: 1px;
    text-transform: uppercase;
  }

  input {
    margin: 10px 0px;
    // display: block;
  }

  // label {
  //   width: 100px;
  //   display: inline-block;
  // }

  .thirds {
    width: 30%;
    margin: 0px;
    //display: inline;
    float: left;
    padding: 0px !important;
    border: 0px;
  }

  a {
    text-decoration: none;
    // text-transform:uppercase;
    color: inherit;
    background-color: inherit;
  }
`;

export default GlobalStyle;
