import React from 'react';
import { useQuery } from '@apollo/client';
import { Link } from '@reach/router';
import logoSmall from '../images/logo192.png';
import IS_LOGGED_IN from '../utils/gqlQueries';
import LogoutButton from './LogoutButton';

const Header = () => {
  const { data } = useQuery(IS_LOGGED_IN);
  return (
    <div className="darkOnLight">
      <div className="content">
        <div className="leftNav">
          <img src={logoSmall} height="40px" width="40px" alt="arrow logo" className="logo" />
          <Link to="/" className="uglySlide">
            <h2>Autotrace</h2>
          </Link>
        </div>
        <div className="rightNav">
          {data.isLoggedIn ? (
            <>
              <Link to="/faq" className="quietButton">
                FAQ
              </Link>
              <Link to="/pricing" className="quietButton">
                Pricing
              </Link>
              <LogoutButton />
              <Link to="/signup" className="brightButton">
                Account
              </Link>
            </>
          ) : (
            <>
              <Link to="/faq" className="quietButton">
                FAQ
              </Link>
              <Link to="/pricing" className="quietButton">
                Pricing
              </Link>
              <div className="right">
                <Link to="/login" className="quietButton">
                  Login
                </Link>
                <Link to="/signup" className="brightButton">
                  Try Now
                </Link>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
