import React from 'react';
import styled from 'styled-components';
import { Router } from '@reach/router';
import Home from './Home';
import BuyNow from './BuyNow';
import Account from './Account';
import GlobalStyle from '../styles/globalStyle';
import LoginPage from './LoginPage';
import SignupPage from './Signup';
import UserConfirmation from './UserConfirmation';
import ConfirmID from './ConfirmID';
import TermsOfServicePage from './TermsOfService';
import Confirmed from './Confirmed';
import Forgot from './Forgot';
import ChangePassword from './ChangePassword';
import ChangePasswordID from './ChangePasswordID';
import ConfirmResetPassword from './ConfirmResetPassword';
import Contact from './Contact';
import GlobalClasses from '../styles/globalClasses';
import FAQ from './FAQ';
import Pricing from './Pricing';
import CheckoutComplete from './CheckoutComplete';

const AppWrapper = styled.div`
  background-color: #fafafa;
`;

export default function SiteRouter() {
  return (
    <>
      <GlobalStyle />
      <GlobalClasses />
      <AppWrapper>
        <Router>
          {/* Some of these are Pages, and some of them are not */}
          <Home path="/" />
          <BuyNow path="/checkout" />
          <Account path="/account" />
          <LoginPage path="/login" />
          <SignupPage path="/signup" />
          <TermsOfServicePage path="/termsofservice" />
          <UserConfirmation path="/userconfirmation">
            <ConfirmID path=":confirmID" />
          </UserConfirmation>
          <Confirmed path="/confirmed" />
          <ConfirmResetPassword path="/confirmreset" />
          <Forgot path="/forgot" />
          <ChangePassword path="/changepassword">
            <ChangePasswordID path=":token" />
          </ChangePassword>
          <Contact path="/contact" />
          <FAQ path="/faq" />
          <Pricing path="/pricing" />
          <CheckoutComplete path="/checkoutcomplete" />
        </Router>
      </AppWrapper>
    </>
  );
}
