import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { LOGIN_USER } from '../utils/gqlMutations';
import isLoggedInVar from '../utils/makeVars';

const LoadableComponent = ({ children, loading }) => (loading && 'loading') || children;

const LoginForm = () => {
  const [login, { loading, error, data: loginResult }] = useMutation(LOGIN_USER, {
    onError() {},
  });

  useEffect(() => {
    if (loginResult?.login.token) {
      isLoggedInVar(loginResult.login.token);
    }
  }, [loginResult]);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    login({ variables: { email, password } });
    setEmail('');
    setPassword('');
  };

  return (
    <LoadableComponent loading={loading}>
      <form onSubmit={handleSubmit}>
        {/* Why do we have error-related rendering twice? */}
        {error && <div>{error.message}</div>}
        {/* Use <label> for labels also use `name` generally and note that this is currently broken */}
        {/* lmaoooooo how 'bout I don't? */}
        {/* No seriously, I'm sorry, I need to fix this but right now styling labels directly seems to break the site */}
        {/* it looks like this is because the input fields across the site are wrapped entirely in a label tag */}
        {/* I don't know why this is so I'm nervous to change it, and deadline */}
        <label htmlFor="email">
          <div className="fieldname">Email:</div>
          <input name="email" type="text" value={email} onChange={(e) => setEmail(e.target.value)} />
        </label>
        {/* <br /> is considered harmful */}
        <br />
        <div className="fieldname">Password:</div>
        <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
        <br />
        <input type="submit" value="Login" className="mediumButton" />
        {error && <h3>Incorrect username or password.</h3>}
      </form>
    </LoadableComponent>
  );
};

export default LoginForm;
