import React from 'react';
import { useMutation, gql } from '@apollo/client';
import { loadStripe } from '@stripe/stripe-js';
import PropTypes from 'prop-types';
import uploadFiles from '../utils/uploadFiles';

const CREATE_CAMPAIGN = gql`
  mutation createCampaign($numEntities: Int!, $userFilename: String!, $sessionId: String!) {
    createCampaign(numEntities: $numEntities, userFilename: $userFilename, sessionId: $sessionId) {
      id
      gcsFilename
    }
  }
`;

const GET_UPLOAD_URL = gql`
  mutation getUploadURL($gcsFilename: String!) {
    getUploadURL(gcsFilename: $gcsFilename)
  }
`;

const CREATE_SESSION = gql`
  mutation createSession($numEntities: Int!) {
    createSession(numEntities: $numEntities)
  }
`;

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC);

const CheckoutButton = ({ acceptedFiles, numEntities }) => {
  const [getUploadURL] = useMutation(GET_UPLOAD_URL);
  const [createCampaign] = useMutation(CREATE_CAMPAIGN);
  const [createSession] = useMutation(CREATE_SESSION);
  const onClick = async () => {
    const session = await createSession({ variables: { numEntities } });
    const sessionId = session.data.createSession;
    const userFilename = acceptedFiles[0].path;
    const campaignMutation = await createCampaign({
      variables: {
        numEntities,
        userFilename,
        sessionId,
      },
    });
    const { gcsFilename } = campaignMutation.data.createCampaign;
    const uploadMutation = await getUploadURL({ variables: { gcsFilename } });
    await uploadFiles(acceptedFiles, uploadMutation.data.getUploadURL);
    const stripe = await stripePromise;
    await stripe.redirectToCheckout({
      sessionId,
    });
  };

  return (
    <div>
      <button type="button" className="mediumButton" onClick={onClick}>
        Checkout
      </button>
    </div>
  );
};

CheckoutButton.propTypes = {
  numEntities: PropTypes.number.isRequired,
  acceptedFiles: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default CheckoutButton;
