import React, { useState } from 'react';
import PropTypes from 'prop-types';

const SignupForm = ({ createUser, signupError }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isChecked, setIsChecked] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    createUser({ variables: { email, password } });
    setEmail('');
    setPassword('');
  };
  return (
    <form onSubmit={handleSubmit}>
      {signupError ? <div>{signupError.message}</div> : ''}
      <div className="fieldname">Email:</div>
      <input type="text" value={email} onChange={(e) => setEmail(e.target.value)} />
      <br />
      <div className="fieldname">Password:</div>
      <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
      <br />
      <input type="checkbox" value={isChecked} onChange={() => setIsChecked(!isChecked)} />
      <a href="/termsofservice" className="quietButton">
        &nbsp;&nbsp;&nbsp;I accept the Terms of Service
      </a>
      <br />
      <input type="submit" value="Sign Up" className="mediumButton" disabled={!isChecked} />

      {signupError && <h3>Incorrect username or password.</h3>}
      {/* {document.getElementById('accept').checked} */}
    </form>
  );
};

SignupForm.propTypes = {
  createUser: PropTypes.func.isRequired,
  signupError: PropTypes.func.isRequired,
};

export default SignupForm;
