import React from 'react';
import { useQuery } from '@apollo/client';
import { Redirect } from '@reach/router';
import IS_LOGGED_IN from '../utils/gqlQueries';
import Header from '../components/Header';
import Footer from '../components/Footer';
import NewCampaign from '../components/NewCampaign';
import Campaigns from '../components/Campaigns';

const Account = () => {
  const { data } = useQuery(IS_LOGGED_IN);
  return (
    <>
      <Header />
      <div className="darkOnLight">
        <div className="content">
          <NewCampaign />
          {data.isLoggedIn ? <Campaigns /> : <Redirect to="/login" />}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Account;
