/* eslint-disable max-len */
import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import autotraceTemplate from '../images/AutotraceTemplate.png';
import autotraceCampaign from '../images/AutotraceCampaign.png';

const FAQ = () => (
  <>
    <Header />
    <div className="darkOnLight">
      <div className="content">
        <h1>Frequently Asked Questions</h1>
        <h3>How accurate is your data?</h3>
        <p>
          Our data is extremely accurate at identifying property owners. Instead of relying on databases, we gather the
          data directly from the a company&apos;s forms of incorporation. Below is a chart comparing the accuracy of
          several VAs and competitors over the same dataset.
        </p>
        <h3>What will my received data look like?</h3>
        <p>
          <img src={autotraceCampaign} alt="" className="autotraceCampaign" />
        </p>
        <h3>How long will it take to receive my data back?</h3>
        <p>One to two business days.</p>
        <h3>How will I know when my data is ready?</h3>
        <p>We&apos;ll send you an email.</p>
        <h3>How much does it cost?</h3>
        <p>Fifty cents per entry. For bulk pricing, reach out to us at [email].</p>
        <h3>Can I test your service on a small order?</h3>
        <p>Absolutely. Our pricing is per entry with no minimum buy.</p>
        <h3>What data should I include?</h3>
        <h3>How should I format my list for skiptracing?</h3>
        <p>Make sure your spreadsheet file includes the following information.</p>
        <p>
          <img src={autotraceTemplate} alt="" className="autotraceTemplate" />
        </p>
      </div>
    </div>
    <Footer />
  </>
);

export default FAQ;
