/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import Papa from 'papaparse';
import CheckoutButton from './CheckoutButton';

const NewCampaign = () => {
  const [numEntities, setNumEntities] = useState(null);
  const accept = ['text/plain', 'application/vnd.ms-excel', '.csv'];
  const maxSize = 1000000;

  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    accept,
    minSize: 0,
    maxSize,
    multiple: false,
  });

  const files = acceptedFiles.map((file) => {
    Papa.parse(file, {
      header: true,
      dynamicTyping: false,
      skipEmptyLines: true,
      transformHeader: (header) => header.toLowerCase().replace(/\W/g, '_'),
      complete(results) {
        setNumEntities(Math.max(results.data.length, 1));
      },
    });
    return (
      <>
        <li key={file.path}>
          {file.path} -{file.size} bytes -{numEntities} Entities
        </li>
        <li>Price - ${(numEntities * 0.5).toFixed(2)}</li>
      </>
    );
  });

  return (
    <section className="container">
      <div className="boxedArea">
        <h1>New Campaign</h1>
        <p>
          Upload a spreadsheet with property addresses and LLC names. In 1-2 business days, we&apos;ll return a
          spreadsheet with the true owners and their contact information.
        </p>
        <p>If you have any questions, reach out to us at contact@autotrace.ai.</p>
        <a href="https://docs.google.com/spreadsheets/d/1zX41UtE4skWOsba6gnTDmIgKSX6KHoO7RP3v0x-lKqk/edit?usp=sharing">
          Download Skip Tracing Template
        </a>
        <div {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} />
          <div>
            {numEntities ? (
              <button type="button" className="brightButton">
                Replace File
              </button>
            ) : (
              <button type="button" className="brightButton">
                Add File
              </button>
            )}
          </div>
        </div>
        <aside>
          <ul>{files}</ul>
        </aside>
        {acceptedFiles.length >= 1 && <CheckoutButton acceptedFiles={acceptedFiles} numEntities={numEntities} />}
      </div>
    </section>
  );
};

export default NewCampaign;
