import React from 'react';
import { useQuery } from '@apollo/client';
import { Redirect, Link } from '@reach/router';
import Signup from '../components/Signup';
import IS_LOGGED_IN from '../utils/gqlQueries';
import Header from '../components/Header';
import Footer from '../components/Footer';

const SignupPage = () => {
  const { data } = useQuery(IS_LOGGED_IN);
  return (
    <div>
      <Header />
      <div className="darkOnLight">
        <div className="content">
          <h1>Sign up</h1>
          {data.isLoggedIn ? <Redirect to="/account" /> : <Signup />}
          <p>
            Already have an account?&nbsp;
            <Link to="/login" className="quietButton">
              Log in
            </Link>
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default SignupPage;
