import React, { useState } from 'react';
import PropTypes from 'prop-types';

const ResetPasswordForm = ({ changePassword }) => {
  const [password, setPassword] = useState('');
  const handleSubmit = (e) => {
    e.preventDefault();
    changePassword({ variables: { password } });
    setPassword('');
  };
  return (
    <form onSubmit={handleSubmit}>
      <label htmlFor="signupSubmit">
        <div className="fieldname">New Password:</div>
        <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
        <br />
      </label>
      <input type="submit" value="Reset Password" className="mediumButton" />
    </form>
  );
};

ResetPasswordForm.propTypes = {
  changePassword: PropTypes.func.isRequired,
};

export default ResetPasswordForm;
