import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';

const CheckoutComplete = () => {
  return (
    <>
      <Header />
      <div className="darkOnLight">
        <div className="content">
          <h1>Order Confirmed!</h1>
          <p>
            We&apos;ll send you an email with your enriched spreadsheet in one-to-two business days. If you have any
            questions about your order, send us a message at{' '}
            <a href="mailto:contact@autotrace.ai">contact@autotrace.ai</a>.
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default CheckoutComplete;
