import React, { useState } from 'react';
import { useMutation, gql } from '@apollo/client';
import { Redirect } from '@reach/router';
import Header from '../components/Header';
import Footer from '../components/Footer';

const RETRIEVE_PASSWORD = gql`
  mutation retrievePassword($email: String!) {
    retrievePassword(email: $email)
  }
`;

const Forgot = () => {
  const [email, setEmail] = useState('');
  const [completed, setCompleted] = useState(false);
  const [retrievePassword] = useMutation(RETRIEVE_PASSWORD);
  const handleSubmit = (e) => {
    e.preventDefault();
    retrievePassword({ variables: { email } });
    setEmail('');
    setCompleted(true);
  };
  return completed ? (
    <Redirect noThrow to="/confirmreset" />
  ) : (
    <>
      <Header />
      <div className="darkOnLight">
        <div className="content">
          <h1>Recover your password</h1>
          <p>Enter your email, and we will send you a recovery link.</p>
          <form onSubmit={handleSubmit}>
            <label htmlFor="retrieve">
              <div className="fieldname">Email:</div>
              <input type="text" value={email} onChange={(e) => setEmail(e.target.value)} />
              <br />
            </label>
            <input type="submit" value="Recover Password" className="mediumButton" />
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Forgot;
