import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';

const Confirmed = () => {
  return (
    <>
      <Header />
      <div className="darkOnLight">
        <div className="content">
          <h1>Please validate your email</h1>
          <p>
            Please check your inbox for a verification email. If you have waited a few minutes and are still do not see
            it, please check spam and trash.
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Confirmed;
