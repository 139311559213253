import { makeVar } from '@apollo/client';

const isLoggedInVar = makeVar(!!sessionStorage.getItem('token'));

// assume we want to keep isLoggedInVar a Boolean on read
export default (tokenOrBool) => {
  if (tokenOrBool === undefined) return isLoggedInVar();
  if (tokenOrBool === false) {
    sessionStorage.clear();
    return isLoggedInVar(tokenOrBool);
  }
  if (tokenOrBool === true) return isLoggedInVar(tokenOrBool);

  // this means we receive a token
  sessionStorage.setItem('token', tokenOrBool);
  return isLoggedInVar(!!tokenOrBool);
};
