/* eslint-disable no-shadow */
import React, { useState } from 'react';
import { useMutation, gql } from '@apollo/client';
import { Redirect } from '@reach/router';
import SignupForm from './SignupForm';

export const CREATE_USER = gql`
  mutation createUser($email: String!, $password: String!) {
    createUser(email: $email, password: $password) {
      token
    }
  }
`;

export default function Signup() {
  const [signupComplete, setSignupComplete] = useState(false);
  const [createUser, { loading, error }] = useMutation(CREATE_USER, {
    onError() {},
    onCompleted() {
      setSignupComplete(true);
    },
  });

  if (loading) return 'loading...';
  if (error) return <SignupForm createUser={createUser} signupError={error} />;

  return signupComplete ? <Redirect noThrow to="/confirmed" /> : <SignupForm createUser={createUser} />;
}
