import React from 'react';
import styled from 'styled-components';
import { Link } from '@reach/router';
import logoSmall from '../images/logo192.png';

const Col = styled.div`
  //   float: right;
  border: 0px;
  width: 30%;
  padding: 0px;
`;

const Footer = () => (
  <div className="darkOnLight">
    <div className="content">
      <Col>
        <Link to="/">
          <img src={logoSmall} height="40px" width="40px" alt="arrow logo" className="logo" />
        </Link>
        <Link to="/">
          <h2>Autotrace</h2>
        </Link>
        <p>
          <p>
            <Link to="/faq" className="quietButton">
              FAQ
            </Link>
          </p>
          <p>
            <Link to="/pricing" className="quietButton">
              Pricing
            </Link>
          </p>
          <p>
            <Link to="/contact" className="quietButton">
              Contact Us
            </Link>
          </p>
          <p>
            <Link to="/termsofservice" className="quietButton">
              Terms of Service
            </Link>
          </p>
        </p>
      </Col>
    </div>
  </div>
);

export default Footer;
