import React from 'react';
import { gql, useQuery } from '@apollo/client';
import PendingCampaigns from './PendingCampaigns';
import PreviousCampaigns from './PreviousCampaigns';

const GET_CAMPAIGNS = gql`
  {
    campaigns {
      id
      numEntities
      completePayment
      userFilename
      gcsFilename
    }
  }
`;

const Campaigns = () => {
  const { data, loading, error } = useQuery(GET_CAMPAIGNS, {
    fetchPolicy: 'network-only',
  });
  if (loading) return <p>Loading...</p>;
  if (error) {
    return (
      <p>
        Error... Try refreshing the page.
        {console.log('unable to load campaigns in campaigns.js', error)}
      </p>
    );
  }
  return (
    <>
      <PendingCampaigns data={data} />
      <PreviousCampaigns data={data} />
    </>
  );
};

export default Campaigns;
