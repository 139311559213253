import React from 'react';
import PropTypes from 'prop-types';
// import { displayCampaigns } from '../utils/utilFunctions';

const displayPendingCampaigns = (campaign) => (
  <p>
    <div key={campaign.id}>{campaign.userFilename}</div>
  </p>
);

const PendingCampaigns = ({ data }) => {
  const pendingCampaigns = data.campaigns.filter((campaign) => campaign.completePayment && !campaign.completeSkiptrace);
  // console.log(pendingCampaigns);
  return (
    <>
      {pendingCampaigns.length > 0 ? (
        <div className="boxedArea">
          <h1>Pending Campaigns</h1>
          {pendingCampaigns.map(displayPendingCampaigns)}
        </div>
      ) : (
        <h1>No Pending Campaigns</h1>
      )}
    </>
  );
};

PendingCampaigns.propTypes = {
  data: PropTypes.node.isRequired,
};
export default PendingCampaigns;
