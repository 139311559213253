/* eslint-disable max-len */
import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';

// Reference: http://leadsherpa.com/#pricing

const Pricing = () => (
  <>
    <Header />
    <div className="darkOnLight">
      <div className="content">
        <h1>Pricing</h1>
        <div className="c-rounded-10 d-flex flex-column bg-white c-middle-shadow h-100">
          <div>
            <h2>Core</h2>
            <p>$0.50 per entity.</p>
          </div>
        </div>
        <div className="c-rounded-10 d-flex flex-column bg-white c-middle-shadow h-100">
          <div>
            <h2>Enterprise</h2>
            <p>Contact us for bulk pricing.</p>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </>
);

export default Pricing;
