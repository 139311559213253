/* eslint-disable react/jsx-curly-brace-presence */
import React from 'react';
import { useQuery } from '@apollo/client';
import { Redirect, Link } from '@reach/router';
import LoginForm from '../components/LoginForm';
import IS_LOGGED_IN from '../utils/gqlQueries';
import Header from '../components/Header';
import Footer from '../components/Footer';

const LoginPage = () => {
  const { data } = useQuery(IS_LOGGED_IN);
  return (
    <div>
      <Header />
      <div className="darkOnLight">
        <div className="content">
          <h1>Log In</h1>
          {data.isLoggedIn ? <Redirect to="/account" /> : <LoginForm />}
          <p>
            {'Forgot your password?  '}
            <Link to="/forgot" className="quietButton">
              Recover Password
            </Link>
          </p>
          <p>
            {"Don't have an account?  "}
            <Link to="/signup" className="quietButton">
              Sign up
            </Link>
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default LoginPage;
