/* eslint-disable no-shadow */
import React, { useState } from 'react';
import { useMutation, gql } from '@apollo/client';
import { Redirect } from '@reach/router';
import ResetPasswordForm from './ResetPasswordForm';

const CHANGE_PASSWORD = gql`
  mutation changePassword($password: String!) {
    changePassword(password: $password)
  }
`;

const ResetPassword = () => {
  const [passwordReset, setPasswordReset] = useState(false);
  const [changePassword] = useMutation(CHANGE_PASSWORD, {
    onError() {
      console.log('Invalid password.');
    },
    onCompleted() {
      setPasswordReset(true);
    },
  });
  return passwordReset ? <Redirect noThrow to="/account" /> : <ResetPasswordForm changePassword={changePassword} />;
};

export default ResetPassword;
