/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';

const ChangePassword = (props) => {
  return (
    <>
      <div>{props.children}</div>
    </>
  );
};

ChangePassword.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ChangePassword;
