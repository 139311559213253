import axios from 'axios';

const uploadFiles = (acceptedFiles, urlSigned) => {
  acceptedFiles.forEach((file) => {
    const options = {
      headers: {
        'Content-Type': 'application/octet-stream',
      },
    };

    axios
      .put(urlSigned, file, options)
      .then(console.log)
      .catch((err) => console.log('err', err));
  });
};

export { uploadFiles as default };
