import React, { useState } from 'react';
import { Redirect } from '@reach/router';
import isLoggedInVar from '../utils/makeVars';

export default function LogoutButton() {
  const [loggedOut, setLoggedOut] = useState(false);
  return loggedOut ? (
    <Redirect noThrow to="/" />
  ) : (
    <button
      type="button"
      className="quietButton"
      onClick={() => {
        isLoggedInVar(false);
        setLoggedOut(true);
      }}
    >
      Logout
    </button>
  );
}
