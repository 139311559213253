/* eslint-disable no-shadow */
import React, { useEffect } from 'react';
import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import ResetPassword from '../components/ResetPassword';
import isLoggedInVar from '../utils/makeVars';
import { CONFIRM_USER } from '../utils/gqlMutations';

const ChangePasswordID = ({ token }) => {
  const [confirmUser] = useMutation(CONFIRM_USER, {
    onCompleted({ confirmUser }) {
      isLoggedInVar(confirmUser);
    },
    onError(error) {
      console.log(error);
      console.log('Problem logging in.');
    },
  });
  useEffect(() => {
    confirmUser({ variables: { token } });
  }, [confirmUser, token]);
  return (
    <>
      <ResetPassword />
    </>
  );
};

ChangePasswordID.propTypes = {
  // This is not always present (see console errors)
  token: PropTypes.string.isRequired,
};

export default ChangePasswordID;
