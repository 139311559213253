/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';

const UserConfirmation = (props) => (
  <>
    <div>{props.children}</div>
  </>
);

UserConfirmation.propTypes = {
  children: PropTypes.node.isRequired,
};

export default UserConfirmation;
