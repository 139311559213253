import React from 'react';
import PropTypes from 'prop-types';
import { gql, useMutation } from '@apollo/client';

const GET_DOWNLOAD_URL = gql`
  mutation getDownloadURL($gcsFilename: String!) {
    getDownloadURL(gcsFilename: $gcsFilename)
  }
`;

const displayPreviousCampaigns = (campaign) => {
  const [getDownloadURL] = useMutation(GET_DOWNLOAD_URL);
  const onClick = async () => {
    const { gcsFilename } = campaign;
    const downloadMutation = await getDownloadURL({ variables: { gcsFilename } });
    const link = document.createElement('a');
    link.href = downloadMutation.data.getDownloadURL;
    link.setAttribute('download', campaign.userFilename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <p>
      <div key={campaign.id}>
        {campaign.userFilename}
        <button type="button" onClick={onClick}>
          Download
        </button>
      </div>
    </p>
  );
};

const PreviousCampaigns = ({ data }) => {
  const previousCampaigns = data.campaigns.filter((campaign) => campaign.completePayment && campaign.completeSkiptrace);
  return (
    <>
      {previousCampaigns.length > 0 ? (
        <div className="boxedArea">
          <h1>Previous Campaigns</h1>
          {previousCampaigns.map(displayPreviousCampaigns)}
        </div>
      ) : (
        <h1>No Previous Campaigns</h1>
      )}
    </>
  );
};

PreviousCampaigns.propTypes = {
  data: PropTypes.node.isRequired,
};
export default PreviousCampaigns;
