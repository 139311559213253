/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-shadow */
import React, { useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import { Redirect } from '@reach/router';
import IS_LOGGED_IN from '../utils/gqlQueries';
import isLoggedInVar from '../utils/makeVars';
import { CONFIRM_USER } from '../utils/gqlMutations';

const ConfirmID = (props) => {
  const { data } = useQuery(IS_LOGGED_IN);
  const [confirmUser] = useMutation(CONFIRM_USER, {
    onCompleted({ confirmUser }) {
      isLoggedInVar(confirmUser);
    },
    onError(error) {
      console.log(error);
      console.log('Problem logging in.');
    },
  });
  useEffect(() => {
    confirmUser({ variables: { token: props.confirmID } });
  }, [confirmUser, props.confirmID]);

  return (
    <>
      {/* {data ? <h1>Account Confirmed!</h1> : <h1>Problem confirming your account.</h1>} */}
      {data.isLoggedIn ? <Redirect noThrow to="/account" /> : <h1>Loading...</h1>}
    </>
  );
};

ConfirmID.propTypes = {
  confirmID: PropTypes.func.isRequired,
};

export default ConfirmID;
