import { createGlobalStyle } from 'styled-components';

const GlobalClasses = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #3B3B3B;
  };

  .sideFlow {
    max-width: 33%;
    float: left;
  }

  .uglySlide {
    margin-top: -20px;
  }

  .brightButton {
    border-color: #1B6DC1;
    color: #1B6DC1;
  }

  .darkOnLight, .lightOnDark, .brightChunk {
    // padding-left: 115px;
  }

  .content {
    max-width: 1010px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    // margin-bottom: 25px;
    // overflow: hidden;
    position: relative;
  }

  .quietButton {
    border: 0px;
    padding-right: 30px;
    // text-transform:uppercase;
    color: #1B6DC1;
    font-size: 1em;
    background-color: inherit;
  }

  .mediumButton {
    border: solid 2px;
    font-size: 1em;
    // max-width: fit-content;
    color: white;
    border-color: white;
    background: #1B6DC1;
  }

  .loudButton {
    font-size: 2em;
    color: #FFFFFF;
    background: #1B6DC1;
    text-align: center;
    border-color: #1B6DC1;
    width: 100%;
    display: inline-block;
  }

  .loudButton, .mediumButton, .brightButton {
    // border-radius: 6px;
    text-transform: uppercase;
    padding: 7px;
    border-style: solid;
    // border-width: 2px;
    // padding-left: 10px;
    padding-right: 10px;
  }

  .loudButton, .mediumButton, .boxedArea, .brightButton {
    border-radius: 6px;
    border-width: 2px;
    padding-left: 10px;
  }

  .brightChunk {
    background:#1B6DC1;
  }

  .lightOnDark {
    background: #3B3B3B;
    min-height: 400px;
    align-items: center;
    display: flex;
    // display: inline-block;
  }

  .lightOnDark, .brightChunk {
    color: white;
  }

  .lightOnDark, .brightChunk, .darkOnLight {
    clear: both;
    padding-top: 25px;
    padding-bottom: 25px;
    overflow: hidden;
  }

  .boxedArea {
    padding: 18px;
    border: 1px #1B6DC1 solid;
    margin: 0px 0px 36px 0px;
  }

  .fieldname {
    display: inline-block;
    min-width: 100px;
  }


  .thirds {
    width: 30%;
    margin: 0px;
    //display: inline;
    float: left;
    padding: 0px !important;
    border: 0px;
  }

  .right {
    float: right;
  }

  .leftNav {
    float: left;
    margin-bottom: 0px;
  }

  .rightNav {
    float: right;
    margin-top: 11px;
  }

  .logo {
  float: left;
  margin-left: -50px;
  }

  .graphic{
    position: absolute;
    bottom: -4px;
    right: 0px;
  }
`;

export default GlobalClasses;
