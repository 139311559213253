import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';

const ConfirmResetPassword = () => {
  return (
    <>
      <Header />
      <div className="darkOnLight">
        <div className="content">
          <h1>Password reset emailed.</h1>
          <p>
            Please check your inbox for a link to change your password. Be sure to check spam and trash if you do not
            find it.
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ConfirmResetPassword;
