import React from 'react';
import NewCampaign from '../components/NewCampaign';
import Header from '../components/Header';
import Footer from '../components/Footer';

const BuyNow = () => (
  <>
    <Header />
    <div className="darkOnLight">
      <div className="content">
        <NewCampaign />
      </div>
    </div>
    <Footer />
  </>
);

export default BuyNow;
